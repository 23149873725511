import variables from "@/router/api";

const role = window.localStorage.getItem("userRole");
let DocMenuConfig: object = [];

//INDIVIDUAL CLIENT SIDEBAR
if (role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
  DocMenuConfig = [
    {
      heading: "Menu",
      pages: [
        {
          heading: "dashboard",
          route: "/dashboard",
          svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          sectionTitle: "Claims",
          svgIcon: "/media/icons/duotone/Weather/Wind.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "reportClaims",
              route: "/claims/report-claims",
            },
            {
              heading: "claimsHistory",
              route: "/claims/claims-history",
            },
          ],
        },
        {
          heading: "Notifications",
          route: "/notifications",
          svgIcon: "/media/icons/duotone/General/Notifications1.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Policies",
          route: "/policy",
          svgIcon: "/media/icons/duotone/Files/File.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Personal Profile",
          route: "/profile",
          svgIcon: "/media/icons/duotone/Communication/Address-card.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Update Profile(KYC)",
          route: "/profile/personal-information",
          svgIcon: "/media/icons/duotone/Design/Edit.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
    {
      heading: "Help Desk",
      pages: [
        {
          heading: "FAQ",
          route: "/faq",
          svgIcon: "/media/icons/duotone/Shopping/Chart-pie.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "About WealthPro",
          route: "/about-wealth-pro",
          svgIcon: "/media/icons/duotone/Code/info-circle.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
  ];
}

//COOPERATIVE CLIENT SIDEBAR
if (role == variables.COOPERATIVE_ADMIN_USER_ROLE) {
  DocMenuConfig = [
    {
      heading: "Coopertaive Menu Section",
      pages: [
        {
          heading: "Dashboard",
          route: "/cooperative/dashboard",
          svgIcon: "/media/icons/duotone/Shopping/Chart-bar1.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          sectionTitle: "Claims",
          route: "/cooperative/members",
          svgIcon: "/media/icons/duotone/Weather/Wind.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "Claims Listing",
              route: "/cooperative/members/claims",
            },
            {
              heading: "Report Claims",
              route: "/cooperative/members/claims/report-claims",
            },
          ],
        },
        {
          sectionTitle: "Members",
          route: "/cooperative/members",
          svgIcon: "/media/icons/duotone/Files/User-folder.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "Add New",
              route: "/cooperative/members/create",
            },
            {
              heading: "All Members",
              route: "/cooperative/members",
            },
          ],
        },
        {
          heading: "Policies",
          route: "/cooperative/policies",
          svgIcon: "/media/icons/duotone/Files/File.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          sectionTitle: "Terms & Conditions",
          route: "#",
          svgIcon: "/media/icons/duotone/Text/Text-height.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "Motor Insurance",
              route: "/cooperative/insurance-packages/motor-insurance",
            },
            {
              heading: "House Insurance",
              route: "/cooperative/insurance-packages/house-insurance",
            },
          ],
        },
      ],
    },
    {
      heading: "Personal Menu Section",
      pages: [
        {
          heading: "dashboard",
          route: "/dashboard",
          svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
          fontIcon: "bi-app-indicator",
        },
        // {
        //   sectionTitle: "My Claims",
        //   svgIcon: "/media/icons/duotone/Weather/Wind.svg",
        //   fontIcon: "bi-app-indicator",
        //   sub: [
        //     {
        //       heading: "reportClaims",
        //       route: "/claims/report-claims",
        //     },
        //     {
        //       heading: "claimsHistory",
        //       route: "/claims/claims-history",
        //     },
        //   ],
        // },
        {
          heading: "My Policies",
          route: "/policy",
          svgIcon: "/media/icons/duotone/Files/File.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Notifications",
          route: "/notifications",
          svgIcon: "/media/icons/duotone/General/Notifications1.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Personal Profile",
          route: "/profile",
          svgIcon: "/media/icons/duotone/Communication/Address-card.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Update Profile(KYC)",
          route: "/profile/personal-information",
          svgIcon: "/media/icons/duotone/Design/Edit.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
    {
      heading: "Help Desk",
      pages: [
        {
          heading: "FAQ",
          route: "/faq",
          svgIcon: "/media/icons/duotone/Shopping/Chart-pie.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "About WealthPro",
          route: "/about-wealth-pro",
          svgIcon: "/media/icons/duotone/Code/info-circle.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
  ];
}

//BROKER USER SIDEBAR
if (role == variables.BROKER_USER_ROLE) {
  DocMenuConfig = [
    {
      heading: "Clients Menu Section",
      pages: [
        {
          heading: "All Clients",
          route: "/broker/clients",
          svgIcon: "/media/icons/duotone/Files/User-folder.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          sectionTitle: "Claims",
          route: "#",
          svgIcon: "/media/icons/duotone/Weather/Wind.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "List",
              route: "/broker/clients/claims",
            },
            {
              heading: "Settlement Offers",
              route: "/broker/clients/claims/settlement-offers",
            },
            {
              heading: "Settlement Receipts",
              route: "/broker/clients/claims/settlement-receipts",
            },
          ],
        },
        {
          heading: "Invoices",
          route: "/broker/clients/invoices",
          svgIcon: "/media/icons/duotone/Files/File.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          sectionTitle: "Policies",
          route: "/broker/clients/policies",
          svgIcon: "/media/icons/duotone/Files/File-done.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "All Policies",
              route: "/broker/clients/policies",
            },
            {
              heading: "Approve Policy Certificates",
              route: "/broker/policies/approve-certificate",
            },
            {
              heading: "Approve Policy Documents",
              route: "/broker/policies/approve-document",
            },
            // {
            //   heading: "Policy Certification",
            //   route: "/broker/policies/upload-certificate",
            // },
            {
              heading: "Policy Documentation",
              route: "/broker/policies/upload-document",
            },
          ],
        },
        {
          sectionTitle: "Policy Migration",
          route: "/broker/policy-migration",
          svgIcon: "/media/icons/duotone/Communication/Flag.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            // {
            //   heading: "Create Rate",
            //   route: "/broker/policy-migration/rate",
            // },
            {
              heading: "Create Policy",
              route: "/broker/policy-migration/create",
            },
            {
              heading: "Existing Policies",
              route: "/broker/policy-migration",
            },
          ],
        },
      ],
    },
    {
      heading: "Cooperative Menu Section",
      pages: [
        {
          heading: "Claims",
          route: "/broker/cooperative-societies/claims",
          svgIcon: "/media/icons/duotone/Weather/Wind.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          sectionTitle: "Cooperative Society",
          route: "#",
          svgIcon: "/media/icons/duotone/Home/Building.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "Add New",
              route: "/broker/cooperative-societies/create",
            },
            {
              heading: "List",
              route: "/broker/cooperative-societies",
            },
            // {
            //   heading: "Umbrella Policies",
            //   route: "/broker/cooperative-societies/umbrella-policy",
            // },
          ],
        },
        {
          sectionTitle: "Umbrella Policies",
          route: "#",
          svgIcon: "/media/icons/duotone/Files/File.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "Add New",
              route: "/broker/cooperative-societies/umbrella-policy/create",
            },
            {
              heading: "List",
              route: "/broker/cooperative-societies/umbrella-policy",
            },
          ],
        },
      ],
    },
    {
      heading: "Personal Menu Section",
      pages: [
        {
          heading: "dashboard",
          route: "/dashboard",
          svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          sectionTitle: "My Claims",
          svgIcon: "/media/icons/duotone/Weather/Wind.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "reportClaims",
              route: "/claims/report-claims",
            },
            {
              heading: "claimsHistory",
              route: "/claims/claims-history",
            },
          ],
        },
        {
          heading: "My Policies",
          route: "/policy",
          svgIcon: "/media/icons/duotone/Files/File.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Notifications",
          route: "/notifications",
          svgIcon: "/media/icons/duotone/General/Notifications1.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Personal Profile",
          route: "/profile",
          svgIcon: "/media/icons/duotone/Communication/Address-card.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Update Profile(KYC)",
          route: "/profile/personal-information",
          svgIcon: "/media/icons/duotone/Design/Edit.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
    {
      heading: "Help Desk",
      pages: [
        {
          heading: "FAQ",
          route: "/faq",
          svgIcon: "/media/icons/duotone/Shopping/Chart-pie.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "About WealthPro",
          route: "/about-wealth-pro",
          svgIcon: "/media/icons/duotone/Code/info-circle.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
  ];
}

//SUPER ADMIN SIDEBAR
if (role == variables.SUPER_ADMIN_USER_ROLE) {
  DocMenuConfig = [
    {
      heading: "Adminstrative Menu Section",
      pages: [
        {
          heading: "Overview",
          route: "/admin/dashboard",
          svgIcon: "/media/icons/duotone/Communication/Dial-numbers.svg",
          fontIcon: "bi-app-indicator",
        },
        // {
        //   heading: "Activity Log",
        //   route: "/activity-log",
        //   svgIcon: "/media/icons/duotone/General/Thunder.svg",
        //   fontIcon: "bi-app-indicator",
        // },
        // {
        //   sectionTitle: "Authorization",
        //   route: "#",
        //   svgIcon: "/media/icons/duotone/General/Lock.svg",
        //   fontIcon: "bi-app-indicator",
        //   sub: [
        //     {
        //       heading: "Permissions",
        //       route: "/authorization/permissions",
        //     },
        //     {
        //       heading: "Roles",
        //       route: "/authorization/roles",
        //     },
        //   ],
        // },
        {
          heading: "Banks",
          route: "/admin/bank",
          svgIcon: "/media/icons/duotone/General/Bank.svg",
          fontIcon: "bi-app-indicator",
        },
        // {
        //   heading: "Business Industries",
        //   route: "/admin/business-industries",
        //   svgIcon: "/media/icons/duotone/Home/Home.svg",
        //   fontIcon: "bi-app-indicator",
        // },
        {
          sectionTitle: "Claims",
          route: "#",
          svgIcon: "/media/icons/duotone/Weather/Wind.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "List",
              route: "/admin/claims",
            },
            {
              heading: "Settlement Offers",
              route: "/admin/claims/settlement-offers",
            },
          ],
        },
        {
          heading: "Department",
          route: "/admin/department",
          svgIcon: "/media/icons/duotone/Design/Bezier-curve.svg",
          fontIcon: "bi-app-indicator",
        },
        // {
        //   heading: "Designations",
        //   route: "/admin/designations",
        //   svgIcon: "/media/icons/duotone/Communication/Flag.svg",
        //   fontIcon: "bi-app-indicator",
        // },
        {
          sectionTitle: "Policies",
          route: "/all-policies",
          svgIcon: "/media/icons/duotone/Files/File.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "All Policies",
              route: "/admin/policies/all-policies",
            },
            {
              heading: "Approve Policy Certificates",
              route: "/admin/policies/approve-certificate",
            },
            {
              heading: "Approve Policy Documents",
              route: "/admin/policies/upload-certificate",
            },
            {
              heading: "Policy Documentation",
              route: "/admin/policies/upload-document",
            },
            // {
            //   heading: "Umbrella Policy",
            //   route: "/admin/cooperative/umbrella-policy",
            // },
          ],
        },
        {
          sectionTitle: "Policy Migration",
          route: "/admin/policy-migration",
          svgIcon: "/media/icons/duotone/Communication/Flag.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            // {
            //   heading: "Create Rate",
            //   route: "/admin/policy-migration/rate",
            // },
            {
              heading: "Create Policy",
              route: "/admin/policy-migration/create",
            },
            {
              heading: "Existing Policies",
              route: "/admin/policy-migration",
            },
            {
              heading: "Policy Dependencies",
              route: "/admin/policy-migration/dependencies",
            },
            {
              heading: "Uploaded Schedules",
              route: "/admin/policy-migration/uploaded-schedules",
            },
          ],
        },
        // {
        //   sectionTitle: "Products",
        //   route: "#",
        //   svgIcon: "/media/icons/duotone/Shopping/Box2.svg",
        //   fontIcon: "bi-app-indicator",
        //   sub: [
        //     {
        //       heading: "Main Category",
        //       route: "/admin/products/main-category",
        //     },
        //     {
        //       heading: "Sub Category",
        //       route: "/admin/products/sub-category",
        //     },
        //   ],
        // },
        {
          heading: "Products",
          route: "/admin/products/main-category",
          svgIcon: "/media/icons/duotone/Shopping/Box2.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Rates",
          route: "/admin/rates",
          svgIcon: "/media/icons/duotone/General/Thunder-move.svg",
          fontIcon: "bi-app-indicator",
        },
        // {
        //   heading: "Report",
        //   route: "/users/sub-category",
        //   svgIcon: "/media/icons/duotone/General/addedByDenk/Adjusters.svg",
        //   fontIcon: "bi-app-indicator",
        // },
        // {
        //   heading: "Revenue Center",
        //   route: "/admin/revenue-center",
        //   svgIcon: "/media/icons/duotone/Communication/RSS.svg",
        //   fontIcon: "bi-app-indicator",
        // },
        {
          heading: "Vehicles",
          route: "/admin/vehicles",
          svgIcon: "/media/icons/duotone/Electric/Iron.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "OCR Test",
          route: "/admin/ocr-test",
          svgIcon: "/media/icons/duotone/Communication/RSS.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
    {
      heading: "Finance Menu Section",
      pages: [
        {
          heading: "Credit Notes",
          route: "/admin/credit-notes",
          svgIcon: "/media/icons/duotone/Shopping/Credit-card.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Debit Notes",
          route: "/admin/debit-notes",
          svgIcon: "/media/icons/duotone/Shopping/Ticket.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Payments",
          route: "/admin/payments",
          svgIcon: "/media/icons/duotone/Shopping/Money.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
    {
      heading: "Users Menu Section",
      pages: [
        // {
        //   heading: "Adjusters",
        //   route: "/users/adjusters",
        //   svgIcon: "/media/icons/duotone/General/addedByDenk/Adjusters.svg",
        //   fontIcon: "bi-app-indicator",
        // },
        {
          sectionTitle: "Administrators",
          route: "#",
          svgIcon: "/media/icons/duotone/Communication/Group.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "Add New",
              route: "/admin/users/administrators/create",
            },
            {
              heading: "List",
              route: "/admin/users/administrators",
            },
          ],
        },
        {
          sectionTitle: "Cooperative Society",
          route: "#",
          svgIcon: "/media/icons/duotone/Home/Building.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "Add New",
              route: "/admin/users/cooperative-societies/create",
            },
            {
              heading: "List",
              route: "/admin/users/cooperative-societies",
            },
          ],
        },
        {
          sectionTitle: "IBN Staffs",
          route: "#",
          svgIcon: "/media/icons/duotone/General/addedByDenk/Adjusters.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "Add New",
              route: "/admin/users/staffs/create",
            },
            {
              heading: "List",
              route: "/admin/users/staffs",
            },
          ],
        },
        {
          sectionTitle: "Individual Clients",
          route: "#",
          svgIcon: "/media/icons/duotone/Communication/Group.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "Add New",
              route: "/admin/users/individual-clients/create",
            },
            {
              heading: "All Clients",
              route: "/admin/users/individual-clients",
            },
            {
              heading: "With Policies",
              route: "/admin/users/individual-clients/with-policies",
            },
          ],
        },
        {
          sectionTitle: "Underwriters",
          route: "#",
          svgIcon: "/media/icons/duotone/General/addedByDenk/Adjusters.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "Add New",
              route: "/admin/users/underwriters/create",
            },
            {
              heading: "Create Package Rate",
              route: "/admin/users/underwriters/package/create",
            },
            {
              heading: "All Underwriters",
              route: "/admin/users/underwriters",
            },
          ],
        },
        // {
        //   heading: "Underwriters",
        //   route: "/admin/users/underwriters",
        //   svgIcon: "/media/icons/duotone/General/addedByDenk/Adjusters.svg",
        //   fontIcon: "bi-app-indicator",
        // },
      ],
    },
    {
      heading: "Personal Menu Section",
      pages: [
        {
          heading: "dashboard",
          route: "/dashboard",
          svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          sectionTitle: "My Claims",
          svgIcon: "/media/icons/duotone/Weather/Wind.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "reportClaims",
              route: "/claims/report-claims",
            },
            {
              heading: "claimsHistory",
              route: "/claims/claims-history",
            },
          ],
        },
        {
          heading: "My Policies",
          route: "/policy",
          svgIcon: "/media/icons/duotone/Files/File.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Notifications",
          route: "/notifications",
          svgIcon: "/media/icons/duotone/General/Notifications1.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Personal Profile",
          route: "/profile",
          svgIcon: "/media/icons/duotone/Communication/Address-card.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Update Profile(KYC)",
          route: "/profile/personal-information",
          svgIcon: "/media/icons/duotone/Design/Edit.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
    {
      heading: "Help Desk",
      pages: [
        {
          heading: "FAQ",
          route: "/faq",
          svgIcon: "/media/icons/duotone/Shopping/Chart-pie.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "About WealthPro",
          route: "/about-wealth-pro",
          svgIcon: "/media/icons/duotone/Code/info-circle.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
  ];
}

//UNDERWRITER SIDERBAR
if (role == variables.UNDERWRITER_USER_ROLE) {
  DocMenuConfig = [
    {
      heading: "Menu",
      pages: [
        {
          heading: "Dashboard",
          route: "/underwriter/dashboard",
          svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Notifications",
          route: "/notifications",
          svgIcon: "/media/icons/duotone/General/Notifications1.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Clients",
          route: "/underwriter/clients",
          svgIcon: "/media/icons/duotone/Files/User-folder.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          sectionTitle: "Policies",
          route: "/cooperative/members",
          svgIcon: "/media/icons/duotone/Weather/Wind.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "Policy List",
              route: "/underwriter/policies",
            },
            {
              heading: "Policy Documentation",
              route: "/underwriter/policies/upload-document",
            },
            {
              heading: "Terms & Conditions",
              route: "/underwriter/policies/terms-and-conditions",
            },
            // {
            //   heading: "Policy Certification",
            //   route: "/underwriter/policies/upload-certificate",
            // },
          ],
        },
        {
          heading: "Credit Notes",
          route: "/underwriter/clients/credit-notes",
          svgIcon: "/media/icons/duotone/Shopping/Credit-card.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          sectionTitle: "Claims",
          svgIcon: "/media/icons/duotone/Weather/Wind.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "All",
              route: "/underwriter/clients/claims",
            },
            {
              heading: "Settled",
              route: "/underwriter/clients/claims/settled",
            },
            {
              heading: "Settlement Offers",
              route: "/underwriter/clients/claims/settlement-offers",
            },
            {
              heading: "Pending Payments",
              route: "/underwriter/clients/claims/pending-payments",
            },
            {
              heading: "Outstanding",
              route: "/underwriter/clients/claims/outstanding",
            },
          ],
        },
      ],
    },
    {
      heading: "Help Desk",
      pages: [
        {
          heading: "FAQ",
          route: "/faq",
          svgIcon: "/media/icons/duotone/Shopping/Chart-pie.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "About WealthPro",
          route: "/about-wealth-pro",
          svgIcon: "/media/icons/duotone/Code/info-circle.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
  ];
}

//FINANCE SIDERBAR FINANCE_USER_ROLE
if (role == variables.FINANCE_USER_ROLE) {
  DocMenuConfig = [
    {
      heading: "Finance Menu Section",
      pages: [
        {
          heading: "Dashboard",
          route: "/finance/dashboard",
          svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Clients",
          route: "/finance/clients",
          svgIcon: "/media/icons/duotone/Files/User-folder.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Credit Notes",
          route: "/finance/credit-notes",
          svgIcon: "/media/icons/duotone/Communication/Dial-numbers.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Debit Notes",
          route: "/finance/debit-notes",
          svgIcon: "/media/icons/duotone/Design/Bezier-curve.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Payments",
          route: "/finance/payments",
          svgIcon: "/media/icons/duotone/Communication/Flag.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Credit Notes Reporting",
          route: "/finance/credit-notes-reporting",
          svgIcon: "/media/icons/duotone/Navigation/Double-check.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Outstanding Debit Notes",
          route: "/finance/debit-notes/outstanding",
          svgIcon: "/media/icons/duotone/Interface/Scatter-up.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Underwriters",
          route: "/finance/underwriters",
          svgIcon: "/media/icons/duotone/General/addedByDenk/Adjusters.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
    {
      heading: "Personal Menu Section",
      pages: [
        {
          heading: "dashboard",
          route: "/dashboard",
          svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          sectionTitle: "My Claims",
          svgIcon: "/media/icons/duotone/Weather/Wind.svg",
          fontIcon: "bi-app-indicator",
          sub: [
            {
              heading: "reportClaims",
              route: "/claims/report-claims",
            },
            {
              heading: "claimsHistory",
              route: "/claims/claims-history",
            },
          ],
        },
        {
          heading: "My Policies",
          route: "/policy",
          svgIcon: "/media/icons/duotone/Files/File.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Notifications",
          route: "/notifications",
          svgIcon: "/media/icons/duotone/General/Notifications1.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Personal Profile",
          route: "/profile",
          svgIcon: "/media/icons/duotone/Communication/Address-card.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "Update Profile(KYC)",
          route: "/profile/personal-information",
          svgIcon: "/media/icons/duotone/Design/Edit.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
    {
      heading: "Help Desk",
      pages: [
        {
          heading: "FAQ",
          route: "/faq",
          svgIcon: "/media/icons/duotone/Shopping/Chart-pie.svg",
          fontIcon: "bi-app-indicator",
        },
        {
          heading: "About WealthPro",
          route: "/about-wealth-pro",
          svgIcon: "/media/icons/duotone/Code/info-circle.svg",
          fontIcon: "bi-app-indicator",
        },
      ],
    },
  ];
}

export default DocMenuConfig;
